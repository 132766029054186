.geo-checkbox{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.checkbox-text {
  line-height: 1rem !important;
  cursor: pointer;
}